@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap");

html,
body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
  font-family: "Source Sans Pro", sans-serif;
  overflow: hidden;
  padding: 0;
  margin: 0;
}

.app {
  display: flex;
  position: relative;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.voucher-button {
  margin-right: 10px;
  padding: 10px 20px;
  background-color: #f0f0f0;
  border: none;
  border-radius: 25px;
  font-size: 15px;
  font-family: "Source Sans Pro", sans-serif;
  /* font-weight: bold; */
  color: #070000;
}

.voucher-button:hover {
  background-color: #e0e0e0;
}

.voucher-button:focus {
  outline: none;
}
